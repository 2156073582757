<template>
  <v-navigation-drawer
    app
    dark
    permanent
    color="#000"
    :clipped="$vuetify.breakpoint.lgAndUp"
  >
    <v-list light class="pa-0">
      <v-list-group
        prepend-icon="mdi-image-multiple-outline"
        class="blue-grey lighten-5 pt-2"
        :value="true"
      >
        <template v-slot:activator>
          <v-list-item-title> 写真管理 </v-list-item-title>
        </template>
        <v-list-item :to="{ name: 'photos', query: { page: 1 } }" exact>
          <v-list-item-title class="black--text"> 写真一覧 </v-list-item-title>
        </v-list-item>
        <v-list-group
          v-if="categories.length > 0"
          prepend-icon="mdi-shape-outline"
          class="blue-grey lighten-5"
          sub-group
          :value="true"
        >
          <template v-slot:activator>
            <v-list-item-title> カテゴリ別一覧 </v-list-item-title>
          </template>
          <v-list-item
            v-for="category in categories"
            :key="category.id"
            :to="{
              name: 'photos',
              query: {
                ...$route.query,
                page: 1,
                'categories[]': [category.id],
              },
            }"
            exact
          >
            <v-list-item-title class="black--text">
              {{ category.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item :to="{ name: 'photos_map' }" exact>
          <v-list-item-title> 地図プレビュー </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'photos_carousel' }" exact>
          <v-list-item-title> メイン写真設定 </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'photos_create' }" exact>
          <v-list-item-title class="black--text">
            写真新規登録
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'photos_bulk_upload' }" exact>
          <v-list-item-title class="black--text">
            CSV一括登録
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'photos_bulk_update' }" exact>
          <v-list-item-title class="black--text">
            CSV一括更新
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'csv_download' }" exact>
          <v-list-item-title class="black--text">
            CSVダウンロード
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-list v-if="isEnabledVideo" class="pa-0">
      <v-list-group prepend-icon="mdi-video" class="pt-2" color="white">
        <template v-slot:activator>
          <v-list-item-title class="white--text">動画管理</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'video_index' }" exact>
          <v-list-item-title class="white--text"> 動画一覧 </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!isOverLimitedVideo"
          :to="{ name: 'video_new' }"
          exact
        >
          <v-list-item-title class="white--text">
            動画新規登録
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-list class="pa-0">
      <v-list-item link :to="{ name: 'categories' }">
        <v-list-item-icon>
          <v-icon dark> mdi-shape-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="white--text"> カテゴリ </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link :to="{ name: 'pages' }">
        <v-list-item-icon>
          <v-icon dark> mdi-dock-window </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="white--text"> ページ </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isAdmin" link :to="{ name: 'users' }">
        <v-list-item-icon>
          <v-icon dark> mdi-account-multiple-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="white--text">
            ユーザー管理
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link :to="{ name: 'me_profile_edit' }">
        <v-list-item-icon>
          <v-icon dark> mdi-account-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="white--text">
            プロフィール
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link :to="{ name: 'settings' }">
        <v-list-item-icon>
          <v-icon dark> mdi-cog-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="white--text">
            ウェブサイトの設定
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item href="/manual.pdf" target="_blank">
        <v-list-item-icon>
          <v-icon dark> mdi-book-open-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="white--text">
            管理画面マニュアル
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :href="termsLink" target="_blank">
        <v-list-item-icon>
          <v-icon dark> mdi-book-open-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="white--text"> 利用規約 </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getData } from "@/axios";

export default {
  name: "SideMenu",
  data() {
    return {
      categories: [],
      videoCountObj: {},
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/me"].role_id === 10;
    },
    termsLink() {
      return process.env.VUE_APP_SERVICE_SITE_URL + "/terms.html";
    },
    isEnabledVideo() {
      return this.$store.getters["auth/client"].plan?.isEnabledVideo;
    },
    isOverLimitedVideo() {
      return (
        this.videoCountObj.counts >=
        this.$store.getters["auth/client"].plan?.video?.limit
      );
    },
  },
  watch: {
    "$store.state.category.countUpdateCategories"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getCategories();
        this.getVideosCount();
      }
    },
  },
  created() {
    this.getCategories();
    this.getVideosCount();
  },
  methods: {
    getCategories() {
      getData("categories").then((res) => {
        this.categories = res.data;
      });
    },
    getVideosCount() {
      getData("videos/counts").then((res) => {
        this.videoCountObj = res.data;
      });
    },
  },
};
</script>
