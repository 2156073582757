import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: function () {
      return import("../views/photos/Index.vue");
    },
  },
  {
    path: "/about",
    name: "about",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/About.vue");
    },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      isPublic: true,
    },
    component: function () {
      return import("../views/Login.vue");
    },
  },
  {
    path: "/password/reset",
    name: "send_email",
    meta: {
      isPublic: true,
    },
    component: function () {
      return import("../views/password/Email.vue");
    },
  },
  {
    path: "/password/reset/:token",
    name: "password_reset",
    meta: {
      isPublic: true,
    },
    component: function () {
      return import("../views/password/Reset.vue");
    },
  },
  {
    path: "/photos",
    name: "photos",
    component: function () {
      return import("../views/photos/Index.vue");
    },
  },
  {
    path: "/photos/map",
    name: "photos_map",
    component: function () {
      return import("../views/map/Index.vue");
    },
  },
  {
    path: "/photos/carousel",
    name: "photos_carousel",
    component: function () {
      return import("../views/carousel-setting/Index.vue");
    },
  },
  {
    path: "/photos/create",
    name: "photos_create",
    meta: "new",
    component: function () {
      return import("../views/photos/Edit.vue");
    },
  },
  {
    path: "/photos/bulk_upload",
    name: "photos_bulk_upload",
    component: function () {
      return import("../views/photos/bulkUpload.vue");
    },
  },
  {
    path: "/photos/bulk_update",
    name: "photos_bulk_update",
    component: function () {
      return import("../views/photos/bulkUpdate.vue");
    },
  },
  {
    path: "/photos/csv_download",
    name: "csv_download",
    component: function () {
      return import("../views/photos/csvDownload.vue");
    },
  },
  {
    path: "/photos/:photoId",
    name: "photos_edit",
    meta: "edit",
    component: function () {
      return import("../views/photos/Edit.vue");
    },
  },

  {
    path: "/videos",
    name: "video_index",
    component: function () {
      return import("../views/videos/Index.vue");
    },
  },
  {
    path: "/videos/new",
    name: "video_new",
    meta: "new",
    component: function () {
      return import("../views/videos/NewAndEdit.vue");
    },
  },
  {
    path: "/videos/:videoId",
    name: "video_edit",
    meta: "edit",
    component: function () {
      return import("../views/videos/NewAndEdit.vue");
    },
  },

  {
    path: "/categories",
    name: "categories",
    component: function () {
      return import("../views/categories/Index.vue");
    },
  },
  {
    path: "/categories/create",
    name: "categories_create",
    meta: "new",
    component: function () {
      return import("../views/categories/Edit.vue");
    },
  },
  {
    path: "/categories/:id",
    name: "categories_edit",
    meta: "edit",
    component: function () {
      return import("../views/categories/Edit.vue");
    },
  },
  {
    path: "/pages",
    name: "pages",
    component: function () {
      return import("../views/pages/Index.vue");
    },
  },
  {
    path: "/pages/create",
    name: "pages_create",
    meta: "new",
    component: function () {
      return import("../views/pages/Edit.vue");
    },
  },
  {
    path: "/pages/:id",
    name: "pages_edit",
    meta: "edit",
    component: function () {
      return import("../views/pages/Edit.vue");
    },
  },
  {
    path: "/users",
    name: "users",
    component: function () {
      return import("../views/users/Index.vue");
    },
  },
  {
    path: "/users/create",
    name: "users_create",
    meta: "new",
    component: function () {
      return import("../views/users/Edit.vue");
    },
  },
  {
    path: "/users/:userId",
    name: "users_edit",
    meta: "edit",
    component: function () {
      return import("../views/users/Edit.vue");
    },
  },
  {
    path: "/me/profile",
    name: "me_profile_edit",
    meta: "edit",
    component: function () {
      return import("../views/me/Edit.vue");
    },
  },
  {
    path: "/settings",
    name: "settings",
    meta: "edit",
    component: function () {
      return import("../views/settings/Index.vue");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
