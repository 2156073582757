<template>
  <v-toolbar height="60px" color="#000" :dark="true">
    <v-toolbar-title color="#fff">
      {{ client }} OpenPhoto管理画面
    </v-toolbar-title>
    <v-spacer />
    <v-badge
      v-if="isLogin"
      :value="newCount > 0"
      class="mr-3"
      color="red"
      :content="newCount"
    >
      <v-icon
        @click="
          dialog = true;
          newCount = 0;
        "
      >
        mdi-information-outline
      </v-icon>
    </v-badge>
    <v-btn
      v-if="isLogin"
      :href="siteLink"
      text
      style="text-transform: none"
      target="_blank"
    >
      {{ siteTitle }}へ
      <v-icon class="pl-1" dense> mdi-open-in-new </v-icon>
    </v-btn>
    <v-btn v-if="isLogin" text @click="handleLogout"> ログアウト </v-btn>
    <v-dialog v-if="isLogin" v-model="dialog" scrollable max-width="500">
      <v-card>
        <v-card-title class="flex justify-space-between">
          What's New
          <v-icon @click="dialog = false"> mdi-close </v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text style="height: 500px">
          <div v-for="(item, i) in news" :key="i" class="mt-4">
            <h2 class="mb-4">
              {{ item.title }}
            </h2>
            <p>{{ formatDate(item.date) }}</p>
            <p>{{ item.content }}</p>
            <v-divider />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "HeaderItem",

  data: () => ({
    dialog: false,
    news: [],
    newCount: 0,
  }),

  computed: {
    isLogin() {
      return this.$store.getters["auth/me"];
    },
    lastLogin() {
      return DateTime.fromSQL(this.$store.getters["auth/me"].last_login);
    },
    siteLink() {
      return `${process.env.VUE_APP_APP_URL}/c/${this.$store.getters["auth/client"].slug}`;
    },
    siteTitle() {
      return this.$store.getters["auth/client"].setting &&
        this.$store.getters["auth/client"].setting.title
        ? this.$store.getters["auth/client"].setting.title
        : this.$store.getters["auth/client"].name;
    },
    client() {
      return this.$store.getters["auth/client"].name;
    },
  },

  watch: {
    news() {
      this.handleNewCount();
    },
    lastLogin() {
      this.handleNewCount();
    },
  },

  created() {
    fetch("/news.json")
      .then((res) => res.json())
      .then((json) => {
        this.news = json;
      });
  },

  methods: {
    handleLogout() {
      this.$store.dispatch("auth/logout");
    },
    handleNewCount() {
      if (this.news.length > 0 && this.$store.getters["auth/me"]) {
        if (this.$store.getters["auth/me"].last_login === null) {
          // はじめてのログイン
          this.newCount = this.news.length;
        } else {
          this.newCount = this.news.filter((news) => {
            const date = DateTime.fromSQL(news.date);
            return date > this.lastLogin;
          }).length;
        }
      } else {
        this.newCount = 0;
      }
    },
    formatDate(value) {
      return DateTime.fromSQL(value).toFormat("yyyy/LL/dd");
    },
  },
};
</script>
