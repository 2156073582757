import "@babel/polyfill";
import "formdata-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { getData } from "@/axios";

import * as VueGoogleMaps from "vue2-google-maps";
import vuetify from "./plugins/vuetify";

// import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
// import '@fortawesome/fontawesome-free/css/all.min.css'

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places",
  },
});

const redirectRoutes = ["login", "send_email", "password_reset"];

const adminRoutes = ["users", "users_create", "users_edit"];

router.beforeEach((to, from, next) => {
  const isAdmin = store.getters["auth/me"].role_id === 10 ? true : false;
  const isDisabledVideo = !store.getters["auth/client"].plan?.isEnabledVideo;
  if (
    to.matched.some((record) => !record.meta.isPublic) &&
    !store.getters["auth/me"]
  ) {
    // 認証が必要なページでmeが無い時
    next({ name: "login" });
  } else if (
    redirectRoutes.indexOf(to.name) !== -1 &&
    store.getters["auth/me"]
  ) {
    // meがあるのにログインページに行こうとする時
    if (from.name === null) {
      next({ name: "photos" });
    } else {
      next({ name: from.name });
    }
  } else if (adminRoutes.indexOf(to.name) !== -1 && !isAdmin) {
    // 管理者アカウント出ない場合にusersに行こうとする時
    if (from.name === null) {
      next({ name: "photos" });
    } else {
      next({ name: from.name });
    }
  } else if (to.name?.startsWith("video_") && isDisabledVideo) {
    // 動画を契約していない時
    const redirectPageName = from.name ?? "photos";
    next({ name: redirectPageName });
  } else if (to.name === "video_new") {
    getData("videos/counts").then((res) => {
      if (res.data.counts >= store.getters["auth/client"].plan?.video?.limit) {
        // 動画件数が制限値以上になっている時
        const redirectPageName = from.name ?? "photos";
        next({ name: redirectPageName });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
