import { getAccessToken, getData } from "../axios";
import router from "../router";

const state = {
  me: {},
  token: "",
  client: {},
};

const getters = {
  token(state) {
    return state.token ? state.token : "";
  },
  me(state) {
    return Object.keys(state.me).length !== 0 ? state.me : "";
  },
  client(state) {
    return Object.keys(state.client).length !== 0 ? state.client : {};
  },
};

const mutations = {
  login(state, token) {
    state.token = token;
  },
  logout(state) {
    state.token = "";
    state.me = {};
    state.client = {};
  },
  setMe(state, me) {
    state.me = me;
  },
  setClient(state, client) {
    state.client = client;
  },
};

const actions = {
  login(context, data) {
    return getAccessToken(data)
      .then((res) => {
        context.commit("login", res.data.access_token);
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  },
  logout(context) {
    context.commit("logout");
    if (router.currentRoute.name !== "login") {
      router.push("/login");
    }
  },
  setMe(context) {
    return getData("me").then((res) => {
      context.commit("setMe", res);
    });
  },
  setClient(context) {
    return getData("clients").then((res) => {
      context.commit("setClient", res.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
